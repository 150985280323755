/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Button,
  FormSuccess,
  Header,
  LayoutUI,
  Stack,
  Subheader,
  Textarea,
  TextField,
  TextStyle,
  Checkbox,
  HeroImage
} from '../../../../ui-kit';
import ROUTES, { API_ROUTES } from '../../../../utils/routes';
import { humanizeCamelCase } from '../../../../utils/string';
import FormError from '../../../../utils/formError';

import HeaderImage from '../../../../assets/images/education-program/scholarship-application/header.jpg';
import PersonalInfoImage from '../../../../assets/images/education-program/scholarship-application/personal-info.jpg';
import SchoolInfoImage from '../../../../assets/images/education-program/scholarship-application/school-info.jpg';
import QualificationsInfoImage from '../../../../assets/images/education-program/scholarship-application/qualifications-info.jpg';
import MiscInfoImage from '../../../../assets/images/education-program/scholarship-application/misc-info.jpg';

import * as styles from './ScholarshipApplication.module.scss';
import Layout from '../../../../components/layout';
import Navbar from '../../../../components/navbar/navbar';
import MobileNavbar from '../../../../components/mobile-navbar/mobile-navbar';

export default class ScholarshipApplication extends React.PureComponent {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',

    personalAddress1: '',
    personalAddress2: '',
    personalCity: '',
    personalState: '',
    personalZip: '',
    personalCountry: '',

    otherQualifications: '',

    schoolName: '',
    schoolAddress1: '',
    schoolAddress2: '',
    schoolCity: '',
    schoolState: '',
    schoolZip: '',
    schoolCountry: '',

    affiliateInformation: '',
    startingDate: '',

    titleISchool: false,
    specialEdProgram: false,
    reducedLunches: false,
    englishSLP: false,
    teacherStudentRatio: false,

    formErrors: undefined,
    formSubmitted: false,
    formSuccess: undefined
  };

  makeInput = (name, label, isRequired = true, isCheckbox = false) => {
    const formattedLabel = label == null ? humanizeCamelCase(name) : label;

    if (!isCheckbox) {
      return (
        <Stack.Item fill>
          <div className={styles.TextInput}>
            <TextField
              label={formattedLabel}
              labelSize="small"
              name={name}
              onChange={this.handleInputChange}
              value={this.state[name]}
              backgroundColor="white"
              isRequired={isRequired}
            />
          </div>
        </Stack.Item>
      );
    }

    return (
      <div className={styles.checkboxContainer}>
        <Stack.Item fill>
          <Checkbox
            color="black"
            inactiveState="white"
            checked={this.state[name]}
            label={label}
            name={name}
            onChange={this.handleInputChange}
          />
        </Stack.Item>
      </div>
    );
  };

  makeCheckbox = (name, label) => this.makeInput(name, label, false, true);

  handleInputChange = event => {
    const { target } = event;
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = () => {
    const data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,

      personal_address1: this.state.personalAddress1,
      personal_address2: this.state.personalAddress2,
      personal_city: this.state.personalCity,
      personal_state: this.state.personalState,
      personal_zip: this.state.personalZip,
      personal_country: this.state.personalCountry,

      other_qualifications: this.state.otherQualifications,

      school_name: this.state.schoolName,
      school_address1: this.state.schoolAddress1,
      school_address2: this.state.schoolAddress2,
      school_city: this.state.schoolCity,
      school_state: this.state.schoolState,
      school_zip: this.state.schoolZip,
      school_country: this.state.schoolCountry,

      affiliate_information: this.state.affiliateInformation,
      starting_date: this.state.startingDate,

      title_i_school: this.state.titleISchool,
      special_ed_program: this.state.specialEdProgram,
      reduced_lunches: this.state.reducedLunches,
      english_slp: this.state.englishSLP,
      teacher_student_ratio: this.state.teacherStudentRatio
    };

    const { path, method } = API_ROUTES.scholarshipApplicationCreate();

    this.setState({ formSubmitted: true });

    fetch(path, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        throw new FormError(response);
      })
      .then(json => {
        this.setState({
          formSuccess: (
            <FormSuccess
              name={json.first_name}
              successPhrase="Thank you for your scholarship application! We'll be reaching out shortly."
              href={ROUTES.education()}
              buttonText="Go Back"
              color="black"
            />
          )
        });
      })
      .catch(error => {
        this.setState({
          formErrors: error.formErrors,
          formSubmitted: false
        });
      });
  };

  render() {
    return this.state.formSuccess ? (
      this.state.formSuccess
    ) : (
      <Layout>
        <Navbar />
        <MobileNavbar />
        <div>
          <div className={styles.HeroImageWrap}>
            <HeroImage
              image={HeaderImage}
              imageSize="contain"
              height="auto"
              imageAlignment="top"
            >
              <LayoutUI narrow>
                <div className={styles.Heading}>
                  <Stack vertical spacing="loose">
                    <TextStyle>
                      <Header>Scholarship Application</Header>
                    </TextStyle>

                    <p>
                      We would like every school to have our Educational
                      Curriculum, but know that some schools have limited
                      funding and cannot afford extra programs. Because we
                      believe in our program, and the success, unity, and life
                      skills it will bring to your students, we have created our
                      scholarship fund, with the help of many generous donors
                      from across the world.
                    </p>
                    <p>
                      Once your application is reviewed, you will be required to
                      provide certain documentation, as well as signatures from
                      at least two other people who will assist or support you
                      in implementing this program at your school.
                    </p>
                  </Stack>
                </div>
              </LayoutUI>
            </HeroImage>
          </div>

          <div className={styles.HeroImageWrap}>
            <HeroImage
              image={PersonalInfoImage}
              imageSize="contain"
              height="auto"
            >
              <LayoutUI narrow>
                <Stack vertical spacing="loose">
                  <Subheader>Your Information:</Subheader>

                  <Stack>
                    {this.makeInput('firstName')}
                    {this.makeInput('lastName')}
                  </Stack>

                  {this.makeInput('personalAddress1', 'Street Address')}
                  {this.makeInput(
                    'personalAddress2',
                    'Street Address Line 2',
                    false
                  )}

                  <Stack>
                    {this.makeInput('personalCity', 'City')}
                    {this.makeInput('personalState', 'State')}
                  </Stack>

                  <Stack>
                    {this.makeInput('personalZip', 'Zip')}
                    {this.makeInput('personalCountry', 'Country')}
                  </Stack>

                  <Stack>
                    {this.makeInput('email')}
                    {this.makeInput('phone')}
                  </Stack>
                </Stack>
              </LayoutUI>
            </HeroImage>
          </div>

          <div className={styles.HeroImageWrap}>
            <HeroImage
              image={SchoolInfoImage}
              imageSize="contain"
              height="auto"
            >
              <LayoutUI narrow>
                <Stack vertical spacing="loose">
                  <Subheader>School Information:</Subheader>

                  {this.makeInput('schoolName', 'Name')}

                  {this.makeInput('schoolAddress1', 'Street Address')}
                  {this.makeInput(
                    'schoolAddress2',
                    'Street Address Line 2',
                    false
                  )}

                  <Stack>
                    {this.makeInput('schoolCity', 'City')}
                    {this.makeInput('schoolState', 'State')}
                  </Stack>

                  <Stack>
                    {this.makeInput('schoolZip', 'Zip')}
                    {this.makeInput('schoolCountry', 'Country')}
                  </Stack>
                </Stack>
              </LayoutUI>
            </HeroImage>
          </div>

          <div className={styles.HeroImageWrap}>
            <HeroImage
              image={QualificationsInfoImage}
              imageSize="contain"
              height="auto"
            >
              <LayoutUI narrow>
                <Stack vertical spacing="loose">
                  <Subheader>Qualifications:</Subheader>

                  {this.makeInput(
                    'affiliateInformation',
                    'How are you affiliated with the school?'
                  )}
                  {this.makeInput(
                    'startingDate',
                    'When would the program be able to start in the school?',
                    false
                  )}
                </Stack>

                <Stack vertical>
                  <div className={styles.CriteriaHeader}>
                    <Subheader size="small">
                      To qualify, you must meet at least two of the following
                      criteria for your site (check at least 2):
                      <span className={styles.Required}>*</span>
                    </Subheader>
                  </div>

                  {this.makeCheckbox('titleISchool', 'Title I School')}
                  {this.makeCheckbox(
                    'specialEdProgram',
                    'You have a special education program'
                  )}
                  {this.makeCheckbox(
                    'reducedLunches',
                    'At least 15% of students with reduced lunches'
                  )}
                  {this.makeCheckbox(
                    'englishSLP',
                    'At least 10% students in an English as a SLP'
                  )}
                  {this.makeCheckbox(
                    'teacherStudentRatio',
                    'Have a teacher to student ratio of 1:30'
                  )}
                </Stack>
              </LayoutUI>
            </HeroImage>
          </div>

          <div className={styles.HeroImageWrap}>
            <HeroImage image={MiscInfoImage} imageSize="contain" height="auto">
              <LayoutUI narrow>
                <Stack vertical spacing="loose">
                  <Subheader>Other Qualifications:</Subheader>

                  <p>
                    Please submit a brief paragraph about why you would like our
                    Project Happiness Educational Curriculum at your school. Be
                    specific. Why do you think your school is a good candidate
                    for our scholarships? If you have a plan for implementing
                    the program, please explain here.
                    <span className={styles.Required}>*</span>
                  </p>

                  <div className={styles.TextInput}>
                    <Textarea
                      onChange={this.handleInputChange}
                      name="otherQualifications"
                      value={this.state.otherQualifications}
                    />
                  </div>
                </Stack>
              </LayoutUI>
            </HeroImage>
          </div>

          <div className={styles.BottomButton}>
            <LayoutUI narrow>
              <TextStyle center>
                <div>
                  <Button
                    color="black"
                    onClick={this.handleSubmit}
                    disabled={this.state.formSubmitted}
                  >
                    Submit
                  </Button>
                </div>
              </TextStyle>
            </LayoutUI>
          </div>
        </div>
      </Layout>
    );
  }
}
